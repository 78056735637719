import React, { useEffect, useState } from "react";
import kebabCase from "lodash/kebabCase";
import { graphql, Link } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import { SiteWrapper } from "../components/SiteWrapper";
import RequestForm from "../components/requestForm";
import Seo from "../components/seo";
import ShareButtons from "../components/sharebuttons";
import { dateConversion, isMp4 } from "../utils/functions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleLogo from "../assets/logos/Circle_Logo.png";
import useWindowDimensions from "../utils/windowSize";
import {
  CardTagButton,
  BlogHeaderVideo,
  BlogHeaderImage,
} from "../page-components/blog/styles";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { ACTIVE_PAGE } from "../components/nav/utils";
import {
  Content,
  ContentWrapper,
  HeaderContainer,
  PostHeader,
  Divider,
} from "../page-components/blog/BlogPost.styles";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
  className?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo loop autoPlay muted style={{ objectPosition: imageFocus }}>
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};
export default function BlogPost({
  data,
  pageContext,
}: {
  data: any;
  pageContext: any;
}) {
  const { width } = useWindowDimensions();
  const { next, prev } = pageContext;
  const post = data.markdownRemark;

  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    return setUrl(window.location.href);
  }, []);
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.BLOGPAGE}>
      <Divider />
      <HeaderContainer>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        />
        <PostHeader>{post.frontmatter.title}</PostHeader>
      </HeaderContainer>
      <ContentWrapper>
        <Content>
          <MediaComponent
            mediaSrc={post.frontmatter.mediaSrc}
            imageFocus={post.frontmatter.imageFocus}
          />

          <br />
          <small>
            {post.frontmatter.tags.map((tag: string, i: number) => (
              <CardTagButton key={i}>
                <small>
                  <Link
                    className="blog-post__tag-link"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                </small>
              </CardTagButton>
            ))}
          </small>

          <small className="small_text">Robotic Imaging</small>
          <small className="small_text">
            {dateConversion(post.frontmatter.date)}
          </small>

          <small style={{ paddingTop: "10px" }}>
            <ShareButtons
              title={post.frontmatter.title}
              url={url}
              tags={post.frontmatter.tags}
            />
          </small>

          <div
            className={`blog-post__html ${
              post.frontmatter.float ? "blog-post__html-float" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </Content>
      </ContentWrapper>
      <Divider />
      <Content>
        <div className="page-navigation">
          {prev ? (
            <Link
              to={prev.path}
              className="page-navigation-prev blog-post__navigation"
              title="Previous blog post"
            >
              <MediaComponent
                mediaSrc={prev.mediaSrc || CircleLogo}
                className={` ${!prev.mediaSrc ? "missing-media" : ""}`}
              />
              <div>
                <ChevronLeftIcon />
                <span>Previous Post</span>
              </div>
              {width && width >= 650 ? <span>{prev.title}</span> : null}
            </Link>
          ) : null}
          {next ? (
            <Link
              to={next.path}
              className="page-navigation-next blog-post__navigation"
              title="Next blog post"
            >
              <MediaComponent
                mediaSrc={next.mediaSrc || CircleLogo}
                className={`${!next.mediaSrc ? "missing-media" : ""}`}
              />
              <div>
                <span>Next Post</span>
                <ChevronRightIcon />
              </div>
              {width && width >= 650 ? <span>{next.title}</span> : null}
            </Link>
          ) : null}
        </div>

        <ContactRequestContainer />
      </Content>
    </SiteWrapper>
  );
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        author
        date(formatString: "MMMM DD, YYYY")
        tags
        mediaSrc
        imageFocus
        float
      }
    }
  }
`;
